@import breakpoints

//Layout Standard Width
$width: 100%
$standard_max_width: 110rem

// Radius
$radius: .25rem

//Layout Standard Height
$min_height: 100vh
$max_height: 90rem
$height: 100vh

// Header bar height
$header_height: 7.5rem

// Margins
$section_margin: 7.5rem

// Paddings
$padding: 3rem
$padding-sm: 1.5rem

// Primary Colors | Changable
$rose: #ddcabe
$angel: #DED9CD
$dark_angel: #CDC3B8
$grey: #B9B399
$red: #b53612
$green: green

// Not that changable
$black: #120700
$white: #f7f1ea
$dark_grey: #7E7E7E

// Fonts
@font-face
    font-weight: 400
    font-family: 'celine_peach'
    src: url('../fonts/celine_peach_sans.ttf') format("truetype")

//@font-face
//    font-weight: 700
//    font-family: 'champagne_limousines'
//    src: url('../fonts/champagne_limousines_bold.ttf') format("truetype")

@font-face
    font-weight: 400
    font-family: 'baskerville'
    src: url('../fonts/librebaskerville/LibreBaskerville-Regular.ttf') format("truetype")

$font-main: 'baskerville'
$font-title: 'baskerville'
