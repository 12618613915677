input
    $padding-side: 1.5rem

    height: 5rem
    font-family: $font-main
    font-size: 1.6rem
    padding: 0 $padding-side
    outline: none
    border: .1rem solid $black
    background-color: $white
    border-radius: $radius !important
    -webkit-appearance: none

    &:focus
        border: .1rem solid $dark_angel


    &--testSize
        height: 30rem
        width: 40rem


.label
    font-family: $font-main
    color: $black
    font-size: 1.5rem
    font-weight: 400

.labelError
    font-family: $font-main
    color: $red
    font-size: 1.3rem
    font-weight: 400

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

/* Firefox */
input[type=number]
    -moz-appearance: textfield
